import _ from 'lodash';
import $ from 'jquery';
import { MetricsPanelCtrl } from 'app/plugins/sdk';
import config from 'app/core/config';
import { transformDataToTable } from './transformers';
import { tablePanelEditor } from './editor';
import { columnOptionsTab } from './column_options';
import { TableRenderer } from './renderer';
import { CustomModification } from './types';
import { isTableData, PanelEvents, PanelPlugin } from '@grafana/data';
import { TemplateSrv } from 'app/features/templating/template_srv';
import { CoreEvents } from 'app/types';
import { getBackendSrv } from '@grafana/runtime';
import { ContextSrv } from 'app/core/services/context_srv';
import { SologsalgTools } from 'app/features/annotations/sologsalg_tools';
import { getTimeSrv } from 'app/features/dashboard/services/TimeSrv';

export class TablePanelCtrl extends MetricsPanelCtrl {
  static templateUrl = 'module.html';
  panelCtrl: MetricsPanelCtrl;
  pageIndex: number;
  dataRaw: any;
  table: any;
  renderer: any;
  editData: any;
  editFields: any;
  editNewValue: any;
  editCurrentValue: any;
  editDropdownValues: any;
  editValidation: any;
  searchValue: any;
  searchAllData: any;
  itemId: string;
  itemName: string;
  versionId: string;
  userEmail: string;

  panelDefaults: any = {
    targets: [{}],
    transform: 'timeseries_to_columns',
    pageSize: null,
    showHeader: true,
    styles: [
      {
        type: 'date',
        pattern: 'Time',
        alias: 'Time',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        align: 'auto',
      },
      {
        unit: 'short',
        type: 'number',
        alias: '',
        decimals: 2,
        colors: ['rgba(245, 54, 54, 0.9)', 'rgba(237, 129, 40, 0.89)', 'rgba(50, 172, 45, 0.97)'],
        colorMode: null,
        pattern: '/.*/',
        thresholds: [],
        align: 'right',
      },
    ],
    columns: [],

    fontSize: '100%',
    sort: { col: 0, desc: true },
  };

  /** @ngInject */
  constructor(
    $scope: any,
    $injector: any,
    templateSrv: TemplateSrv,
    private annotationsSrv: any,
    private $sanitize: any,
    private variableSrv: any,
    contextSrv: ContextSrv
  ) {
    super($scope, $injector);

    const variables = this.dashboard.getVariables();
    this.itemId = SologsalgTools.getVaribleByName('item', variables);
    this.itemName = SologsalgTools.getVariableTextByValue('item', this.itemId, variables);
    this.versionId = SologsalgTools.getVaribleByName('version', variables);
    this.userEmail = this.contextSrv.user.email;

    this.pageIndex = 0;

    if (this.panel.styles === void 0) {
      this.panel.styles = this.panel.columns;
      this.panel.columns = this.panel.fields;
      delete this.panel.columns;
      delete this.panel.fields;
    }

    _.defaults(this.panel, this.panelDefaults);

    this.events.on(PanelEvents.dataReceived, this.onDataReceived.bind(this));
    this.events.on(PanelEvents.dataSnapshotLoad, this.onDataReceived.bind(this));
    this.events.on(PanelEvents.editModeInitialized, this.onInitEditMode.bind(this));
    this.events.on(PanelEvents.initPanelActions, this.onInitPanelActions.bind(this));
  }

  onInitEditMode() {
    this.addEditorTab('Options', tablePanelEditor, 2);
    this.addEditorTab('Column Styles', columnOptionsTab, 3);
  }

  onInitPanelActions(actions: any[]) {
    actions.push({ text: 'Export CSV', click: 'ctrl.exportCsv()' });
  }

  issueQueries(datasource: any) {
    this.pageIndex = 0;

    if (this.panel.transform === 'annotations') {
      return this.annotationsSrv
        .getAnnotations({
          dashboard: this.dashboard,
          panel: this.panel,
          range: this.range,
        })
        .then((anno: any) => {
          this.loading = false;
          this.dataRaw = anno;
          this.pageIndex = 0;
          this.render();
          return { data: this.dataRaw }; // Not used
        });
    }

    return super.issueQueries(datasource);
  }

  onDataReceived(dataList: any) {
    if (this.searchValue) {
      this.searchValue = '';
      this.searchAllData = null;
    }
    this.dataRaw = dataList;
    this.pageIndex = 0;

    // automatically correct transform mode based on data
    if (this.dataRaw && this.dataRaw.length) {
      if (isTableData(this.dataRaw[0])) {
        this.panel.transform = 'table';
      } else {
        if (this.dataRaw[0].type === 'docs') {
          this.panel.transform = 'json';
        } else {
          if (this.panel.transform === 'table' || this.panel.transform === 'json') {
            this.panel.transform = 'timeseries_to_rows';
          }
        }
      }
    }

    this.render();
  }

  render() {
    const variables = this.dashboard.getVariables();
    this.itemId = SologsalgTools.getVaribleByName('item', variables);
    this.itemName = SologsalgTools.getVariableTextByValue('item', this.itemId, variables);
    this.versionId = SologsalgTools.getVaribleByName('version', variables);
    this.table = transformDataToTable(this.dataRaw, this.panel);
    this.table.sort(this.panel.sort);
    this.renderer = new TableRenderer(
      this.panel,
      this.table,
      this.dashboard.isTimezoneUtc(),
      this.$sanitize,
      this.templateSrv,
      config.theme.type
    );
    return super.render(this.table);
  }

  toggleColumnSort(col: any, colIndex: any) {
    // remove sort flag from current column
    if (this.table.columns[this.panel.sort.col]) {
      this.table.columns[this.panel.sort.col].sort = false;
    }

    if (this.panel.sort.col === colIndex) {
      if (this.panel.sort.desc) {
        this.panel.sort.desc = false;
      } else {
        this.panel.sort.col = null;
      }
    } else {
      this.panel.sort.col = colIndex;
      this.panel.sort.desc = true;
    }
    this.render();
  }

  exportCsv() {
    const scope = this.$scope.$new(true);
    scope.tableData = this.renderer.render_values();
    scope.panel = 'table';
    this.publishAppEvent(CoreEvents.showModal, {
      templateHtml: '<export-data-modal panel="panel" data="tableData"></export-data-modal>',
      scope,
      modalClass: 'modal--narrow',
    });
  }

  updateSearch() {
    if (this.searchValue.length > 0) {
      // Deep clone all the original data
      if (!this.searchAllData) {
        this.searchAllData = JSON.parse(JSON.stringify(this.dataRaw));
      }
      // Search for in all rows and columns
      const test = this.searchAllData[0].rows.filter(
        (row: any) =>
          row.filter(
            (p: any) =>
              p != null &&
              p
                .toString()
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) > -1
          ).length > 0
      );
      // Set resulting rows as dataset for table
      this.dataRaw[0].rows = test;
    } else {
      // Reset original data into table
      if (this.searchAllData) {
        this.dataRaw = this.searchAllData;
        this.searchAllData = null;
      }
    }
    this.render();
  }

  clearSearch() {
    this.searchValue = '';
    this.updateSearch();
  }

  async wait() {
    var start = new Date().getTime();
    var end = start;
    while (end < start + 2000) {
      end = new Date().getTime();
    }
    getTimeSrv().refreshDashboard();
  }

  sendData() {
    if (this.editNewValue) {
      const orgId = this.contextSrv.user.orgId;
      let data: CustomModification = {
        orgId: orgId,
        panelName: this.editData.panelName,
        valueColumnName: this.editData.valueColumnName,
        metadata: JSON.stringify(this.editFields),
        value: this.editNewValue.toString(),
      };
      getBackendSrv()
        .post(`/custom/api/org/${orgId}/modification`, data)
        .then(() => this.closeEdit())
        .catch(err => {
          console.warn(err);
        });
      this.wait();
    }
  }

  sendNewData(newData: any) {
    const orgId = this.contextSrv.user.orgId;
    newData.item = this.itemId;
    newData.version = this.versionId;
    newData.user = this.userEmail;
    let data: CustomModification = {
      orgId: orgId,
      panelName: 'what_if_campaign',
      valueColumnName: 'new_campain',
      metadata: JSON.stringify(newData),
    };
    getBackendSrv()
      .post(`/custom/api/org/${orgId}/modification`, data)
      .then(() => this.events.emit(PanelEvents.refresh))
      .catch(err => {
        console.warn(err);
      });
  }

  closeEdit() {
    this.editFields = null;
    this.editData = null;
    this.editNewValue = null;
    this.refresh();
  }

  link(scope: any, elem: JQuery, attrs: any, ctrl: TablePanelCtrl) {
    let data: any;
    const panel = ctrl.panel;
    let pageCount = 0;
    function editRow(e: any) {
      if (e.target.dataset.value) {
        ctrl.editData = JSON.parse(e.target.dataset.value);
        ctrl.editFields = ctrl.editData.data;
        ctrl.editCurrentValue = ctrl.editData.currentValue;
        ctrl.editValidation = ctrl.editData.editValidation;

        if (
          ctrl.editValidation &&
          ctrl.editValidation === 'dropdown' &&
          ctrl.editData.dropdownValues &&
          ctrl.editData.dropdownValues.indexOf(ctrl.editCurrentValue) >= 0
        ) {
          ctrl.editNewValue = ctrl.editCurrentValue;
        } else if (ctrl.editValidation && ctrl.editValidation === 'dropdown' && ctrl.editData.dropdownValues) {
          ctrl.editNewValue = ctrl.editData.dropdownValues[0];
        } else {
          ctrl.editNewValue = null;
        }
        ctrl.editDropdownValues = ctrl.editData.dropdownValues;
        ctrl.refresh();
      }
    }

    function getTableHeight() {
      let panelHeight = ctrl.height;

      if (pageCount > 1) {
        panelHeight -= 26;
      }
      if (ctrl.panel.search) {
        panelHeight -= 30;
      }

      return panelHeight - 31 + 'px';
    }

    function appendTableRows(tbodyElem: JQuery) {
      ctrl.renderer.setTable(data);
      tbodyElem.empty();
      tbodyElem.html(ctrl.renderer.render(ctrl.pageIndex));
    }

    function switchPage(e: any) {
      const el = $(e.currentTarget);
      ctrl.pageIndex = parseInt(el.text(), 10) - 1;
      renderPanel();
    }

    function appendPaginationControls(footerElem: JQuery) {
      footerElem.empty();

      const pageSize = panel.pageSize || 100;
      pageCount = Math.ceil(data.rows.length / pageSize);
      if (pageCount === 1) {
        return;
      }

      const startPage = Math.max(ctrl.pageIndex - 3, 0);
      const endPage = Math.min(pageCount, startPage + 9);

      const paginationList = $('<ul></ul>');

      for (let i = startPage; i < endPage; i++) {
        const activeClass = i === ctrl.pageIndex ? 'active' : '';
        const pageLinkElem = $(
          '<li><a class="table-panel-page-link pointer ' + activeClass + '">' + (i + 1) + '</a></li>'
        );
        paginationList.append(pageLinkElem);
      }

      footerElem.append(paginationList);
    }

    function renderPanel() {
      const panelElem = elem.parents('.panel-content');
      const rootElem = elem.find('.table-panel-scroll');
      const tbodyElem = elem.find('tbody');
      const footerElem = elem.find('.table-panel-footer');

      elem.css({ 'font-size': panel.fontSize });
      panelElem.addClass('table-panel-content');

      appendTableRows(tbodyElem);
      appendPaginationControls(footerElem);

      var jq: any = $('.datepicker');
      jq.datepicker();

      rootElem.css({ 'max-height': getTableHeight() });
    }

    // hook up link tooltips
    elem.tooltip({
      selector: '[data-link-tooltip]',
    });

    function addFilterClicked(e: any) {
      const filterData = $(e.currentTarget).data();
      const options = {
        datasource: panel.datasource,
        key: data.columns[filterData.column].text,
        value: data.rows[filterData.row][filterData.column],
        operator: filterData.operator,
      };

      ctrl.variableSrv.setAdhocFilter(options);
    }

    elem.on('click', '.table-btn', editRow);
    elem.on('click', '.table-panel-page-link', switchPage);
    elem.on('click', '.table-panel-filter-link', addFilterClicked);

    const unbindDestroy = scope.$on('$destroy', () => {
      elem.off('click', '.table-btn');
      elem.off('click', '.table-panel-page-link');
      elem.off('click', '.table-panel-filter-link');
      unbindDestroy();
    });

    ctrl.events.on(PanelEvents.render, (renderData: any) => {
      data = renderData || data;
      if (data) {
        renderPanel();
      }
      ctrl.renderingCompleted();
    });
  }
}

export const plugin = new PanelPlugin(null);
plugin.angularPanelCtrl = TablePanelCtrl;
plugin.setNoPadding();
