import _ from 'lodash';
import $ from 'jquery';
import coreModule from 'app/core/core_module';
import alertDef from '../alerting/state/alertDef';
import { DashboardSrv } from '../dashboard/services/DashboardSrv';
import { ContextSrv } from 'app/core/services/context_srv';
import { SologsalgTools } from './sologsalg_tools';

/** @ngInject */
export function annotationTooltipDirective(
  $sanitize: any,
  dashboardSrv: DashboardSrv,
  contextSrv: ContextSrv,
  $compile: any
) {
  function sanitizeString(str: string) {
    try {
      return $sanitize(str);
    } catch (err) {
      console.log('Could not sanitize annotation string, html escaping instead');
      return _.escape(str);
    }
  }

  return {
    restrict: 'E',
    scope: {
      event: '=',
      onEdit: '&',
    },
    link: (scope: any, element: JQuery) => {
      const event = scope.event;
      let title = event.title;
      let text = event.text;

      let tooltip = '<div class="graph-annotation">';

      if (event.alertId) {
        const stateModel = alertDef.getStateDisplayModel(event.newState);
        title = `<i class="${stateModel.iconClass}"></i> ${stateModel.text}`;
        text = alertDef.getAlertAnnotationInfo(event);
        if (event.text) {
          text = text + '<br />' + event.text;
        }
      } else if (title) {
        text = title + '<br />' + (_.isString(text) ? text : '');
        title = '';
      }
      let variables = dashboardSrv.dashboard.getVariables();
      const currentSite = SologsalgTools.getVaribleByName('site', variables);
      const currentItem = SologsalgTools.getVaribleByName('item', variables);

      const tags = event.tags;
      const siteId = SologsalgTools.findTag('siteId', tags);
      const itemId = SologsalgTools.findTag('itemId', tags);

      let header = `<div class="graph-annotation__header_right">`;
      if (siteId && currentSite === siteId.value.toString() && itemId && currentItem === itemId.value.toString()) {
        header += `<div><span class="pointer graph-annotation__edit-icon" ng-click="onEdit()"><i class="fa fa-pencil-square"></i> Edit event</span></div>`;
      }
      header += `</div>`;
      tooltip += header;
      tooltip += '<div class="graph-annotation__body">';

      // Hendelse opprettet paa annet nivaa
      if ((siteId && currentSite !== siteId.value.toString()) || (itemId && currentItem !== itemId.value.toString())) {
        let site = SologsalgTools.getVariableTextByValue('site', siteId.value.toString(), variables);
        let item = SologsalgTools.getVariableTextByValue('item', itemId.value.toString(), variables);
        tooltip += `<p>The event is set at another hierarchy level. Please access the correct level to apply any changes.<br><span class="label label-tag small">Site: ${site}<br>Article: ${item}</span><br></p>`;
      }

      const ignoreSale = SologsalgTools.findTag('ignoreSale', tags);
      if (ignoreSale) {
        tooltip += '<p class="label label-tag">Ignore sales data</p>';
      }

      if (text) {
        tooltip +=
          '<p>Comment:<br><span class="label label-tag small">' +
          sanitizeString(text.replace(/\n/g, '<br>')) +
          '</span><br/></p>';
      }

      const selectedAction = SologsalgTools.findTag('selectedAction', tags);

      // Markere aktivitet
      if (selectedAction && selectedAction.value === 1) {
        const selectedActivity = SologsalgTools.findTag('selectedActivity', tags);
        if (selectedActivity) {
          tooltip += `<p>Marked activity:<br><span class="label label-tag small"> 
            ${SologsalgTools.eventActivities[selectedActivity.value].name}
          </span><br/></p>`;
        }
      }
      // Justere prognose (prosent)
      if (selectedAction && (selectedAction.value === 2 || selectedAction.value === 4)) {
        const selectedAdjustValue = SologsalgTools.findTag('selectedAdjustValue', tags);
        if (selectedAdjustValue) {
          tooltip += `<p>Forecast override percent:<br><span class="label label-tag small"> 
            ${SologsalgTools.eventAdjustValues[selectedAdjustValue.value].name}
          </span><br/></p>`;
        }
      }

      if (selectedAction && selectedAction.value === 2) {
        const selectedAdjustValid = SologsalgTools.findTag('selectedAdjustValid', tags);
        if (selectedAdjustValid) {
          tooltip += `<p>Duration for override:<br><span class="label label-tag small"> 
            ${SologsalgTools.eventAdjustValid[selectedAdjustValid.value].name}
          </span><br/></p>`;
        }
      }

      if (selectedAction && selectedAction.value === 4) {
        const selectedHistoricalForecastId = SologsalgTools.findTagAsString('selectedHistoricalForecastId', tags);
        if (selectedHistoricalForecastId) {
          tooltip += `<p>Used historical forecast:<br><span class="label label-tag small"> 
            ${selectedHistoricalForecastId.value.toString()}
          </span><br/></p>`;
        }
      }

      // Overstyre prognose (antall)
      if (selectedAction && selectedAction.value === 3) {
        const adjustCount = SologsalgTools.findTagAsString('adjustCount', tags);
        if (adjustCount) {
          tooltip += `<p>Forecast override quantity:<br><span class="label label-tag small"> 
            ${adjustCount.value}
          </span><br/></p>`;
        }
      }

      const userEmail = SologsalgTools.findTagAsString('userEmail', tags);
      if (userEmail) {
        tooltip += `<p>Updated / last changed by:<br><span class="label label-tag small"> 
          ${userEmail.value}
        </span><br/></p>`;
      }

      tooltip += '</div>';
      tooltip += '</div>';

      const $tooltip = $(tooltip);
      $tooltip.appendTo(element);

      $compile(element.contents())(scope);
    },
  };
}

coreModule.directive('annotationTooltip', annotationTooltipDirective);
